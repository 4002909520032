@use "@angular/material" as mat;

snack-bar-container {
    &.mat-primary {
        color: white !important;
        background-color: mat.get-color-from-palette($primary-palette, 600);
    }

    &.mat-success {
        color: white !important;
        background-color: mat.get-color-from-palette($success-palette, 600);
    }

    &.mat-warn {
        color: white !important;
        background-color: mat.get-color-from-palette($warn-palette, 600);
    }

    &.mat-accent {
        color: white !important;
        background-color: mat.get-color-from-palette($accent-palette, 600);
    }

    .mat-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}