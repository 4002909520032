::-webkit-scrollbar {
  width: clamp(5px, 1vw, 20px);
  height: clamp(5px, 1vw, 100%);
}

::-webkit-scrollbar-thumb {
  background: #CCC9;
  cursor: pointer;
  border-radius: 0.2rem;
}

@media (hover: none) {
  ::-webkit-scrollbar {
    width: 2.5px;
    background: #fff7;
    pointer-events: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #000a;
    pointer-events: none;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

* {
  scrollbar-color: #EEE9 transparent;
  scrollbar-width: thin;
}