@use "@angular/material" as mat;

table.mat-table.cdk-table {
  width: 100%;
  display: table;
  background-color: transparent;

  button {
    margin: 0 clamp(2.5px, .25vh, 10px);
  }

  th, td {
    padding: 5px 12px;

    &:first-child {
      padding-left: clamp(6px, 2vw, 20px);
    }
    &:last-child {
      padding-right: clamp(6px, 2vw, 20px);
    }
  }

  thead tr.mat-header-row {
    background-color: darken(mat.get-color-from-palette($background, 'card'), 10%);

    th {
      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  tbody tr {
    &:not(.double):nth-child(2n + 1), &.double:nth-child(4n + 1), &.double:nth-child(4n + 2) {
      background-color: mat.get-color-from-palette($background, 'card');
    }
    &:not(.double):nth-child(2n), &.double:nth-child(4n + 3), &.double:nth-child(4n) {
      background-color: darken(mat.get-color-from-palette($background, 'card'), 6%);
    }
  }

  th {
    color: mat.get-color-from-palette($primary-palette, 400);
    font-size: 12.5pt;
    font-weight: 600 !important;
  }

  &.border-radius {
    tbody tr {
      &:not(.collapsed):last-of-type, &.double.collapse:nth-last-of-type(2) {
        td {
          &:first-child {
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

.mat-paginator {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.mat-paginator-container {
  @media only screen and (max-width: 576px)  {
    justify-content: center !important;
  }
}
.mat-paginator-page-size {
  align-items: center !important;


  .mat-paginator-page-size-select {
    margin: 6px 0 !important;

    display: flex !important;
    align-items: center !important;

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-select-arrow-wrapper {
      transform: none;
    }

    .mat-form-field-wrapper {
      width: 56px;
      padding: 0;
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-paginator-range-actions {
  .mat-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .mat-paginator-range-label {
    white-space: pre-line;
  }
}