.dashboard-overlay {
  height: 100%;
  width: 100%;
  background-color: black;

  >* {
    width: 100%;
  }

  .dashboard-overlay-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .dashboard-overlay-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-height: calc(100% - 80px);
      overflow: hidden;

      .dashboard-overlay-info {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 1.2;
        padding: calc(clamp(16px, 2vmin, 40px));


        .dashboard-overlay-title {
          text-transform: uppercase;
          font-weight: bold;
          text-align: center;
          font-stretch: semi-expanded;
          font-size: clamp(20pt, 10vw, 100pt) !important;
          display: flex;
          align-items: center;

          mat-icon {
            font-size: 1em;
            height: 1em;
            width: 1em;
            margin-right: 10px;
          }

          span {
            font-size: 1em;
            line-height: normal;
            display: inline-block;
          }
        }

        img.logo {
          display: block;
          margin-top: auto;
          margin-bottom: 3vh;
          justify-self: flex-end;
          max-height: 15vh;
          width: 40vw;
          filter: grayscale(100%) brightness(200%);
        }

        p {
          text-align: center;
          font-size: clamp(15pt, 6.5vw, 50pt) !important;
        }

        .append {
          margin-top: 7.5vh;
          text-align: center;
          font-size: clamp(12.5pt, 5.5vw, 40pt) !important;
          opacity: .65;
        }
      }
    }
  }
}