html, body { height: 100%; width: 100%; position: fixed; }
body.mat-typography {
  margin: 0;

  *:not(mat-icon,.mat-icon) {
    font-family: Gilroy, Roboto, "Helvetica Neue", sans-serif !important;

    &.righteous, &.righteous *:not(mat-icon,.mat-icon) {
      font-family: Righteous, Gilroy, Roboto, "Helvetica Neue", sans-serif !important;
    }
  }

  &.mat-app-background {
    background-color: #181818;
  }
}

.container-flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

router-outlet {
  display: none !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}
