@use "@angular/material" as mat;

button mat-icon {
  margin-top: -3px;
}

button:not([mat-icon-button]) mat-icon:not(:last-child) {
  margin-left: -0.25em;
  margin-right: 0.2em;
}

button.mat-button-base {
  line-height: 40px;
  font-size: 18px;
  &:not(.mat-icon-button) {
    padding-top: 2px;
  }
}

.mat-button-base.mat-success,
.mat-stroked-button.mat-success {
  color: mat.get-color-from-palette($success-palette, 500);
}
.mat-button-base.mat-success:hover,
.mat-stroked-button.mat-success:hover {
  background-color: transparent;
}

.mat-button-base.mat-raised-button.mat-success,
.mat-button-base.mat-flat-button.mat-success,
.mat-button-base.mat-fab.mat-success,
.mat-button-base.mat-mini-fab.mat-success {
  color: #f0fff3;
  background-color: mat.get-color-from-palette($success-palette, 500);

  &.mat-button-disabled {
    background-color: mat.get-color-from-palette($background, disabled-button);
    color: mat.get-color-from-palette($foreground, disabled-button);
  }
}

.mat-icon-button.mat-success {
  color: mat.get-color-from-palette($success-palette, 500);
}