@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/gilroy-light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/gilroy-bold.woff") format("woff");
}

@font-face {
  font-family: "Righteous";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/righteous-regular.ttf") format("truetype");
}