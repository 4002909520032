@use "@angular/material" as mat;

.text-primary {
    color: mat.get-color-from-palette($primary-palette, 500);
}

.text-success {
    color: mat.get-color-from-palette($success-palette, 500);
}

.text-accent {
    color: mat.get-color-from-palette($accent-palette, 500);
}

.text-warn {
    color: mat.get-color-from-palette($warn-palette, 500);
}

.text-white {
    color: #FFF;
}

.text-black {
    color: #000;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}