@use "@angular/material" as mat;

$outline-color-dark:  mat.get-color-from-palette($background, 'card', 0.3);
$outline-color-light: mat.get-color-from-palette($foreground, divider, 0.3);

mat-form-field {
  min-width: 0px;

  &.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.8em 0 0.9em 0;
  }

  input {
    height: 1.3em;

    &:not(.case-sensitive) {
      text-transform: uppercase;
    }
  }

  .mat-form-field-subscript-wrapper {
    margin: 0.4em 0;
  }
}

.form {
  height: 100%;
  font-size: 1rem;

  mat-form-field {
    display: block;
  }
}

mat-form-field.mat-form-field-appearance-fill {
  margin-bottom: 0.25em;

  &.mat-focused .mat-form-field-flex {
    border-color: mat.get-color-from-palette($primary-palette, 400);
  }

  &:not(.mat-focused, .mat-form-field-invalid) .mat-form-field-flex:hover {
    border-color: #fff;
  }

  &.mat-form-field-invalid .mat-form-field-flex {
    border-color: mat.get-color-from-palette($warn-palette, 400);
  }

  .mat-form-field-flex {
    box-sizing: border-box;
    border: solid 1px $outline-color-light;
    border-radius: 4px;
    padding: 0.5em 0.5em 0;
    transition: border-color 300ms;

    .mat-form-field-infix {
      padding-bottom: 0.5em;
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  input::-webkit-calendar-picker-indicator{
    filter: brightness(0) invert(1) opacity(75%);
  }
}

mat-form-field.black.mat-form-field-appearance-fill {
  &.mat-focused .mat-form-field-flex {
    border-color: mat.get-color-from-palette($primary-palette, 400);

    .mat-form-field-label, .mat-select-arrow {
      color: mat.get-color-from-palette($primary-palette, 400);
    }
  }

  &:not(.mat-focused, .mat-form-field-invalid) .mat-form-field-flex:hover {
    border-color: #000;
  }

  &.mat-form-field-invalid .mat-form-field-flex {
    border-color: mat.get-color-from-palette($warn-palette, 400);

    .mat-form-field-label, .mat-select-arrow {
      color: mat.get-color-from-palette($warn-palette, 400);
    }
  }

  .mat-form-field-flex {
    border-color: $outline-color-dark;
    background-color: #fff5;
  }

  .mat-form-field-label, .mat-select-arrow {
    color: opacify($outline-color-dark, 1);
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-value {
    color: inherit;
  }

  input::-webkit-calendar-picker-indicator{
    filter: opacity(75%);
  }

  mat-hint {
    color: opacify($outline-color-dark, 1);
  }
}

mat-slide-toggle.black:not(.mat-checked) .mat-slide-toggle-bar {
  background-color: #00000061;

  .mat-slide-toggle-thumb {
    background-color: #fafafa;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
